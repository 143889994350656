<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">系统设置</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">鉴定机构设置</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl">
          <div class="searchbox">
            <div title="机构名称" class="searchboxItem ci-full">
              <span class="itemLabel">机构名称:</span>
              <el-select
                  size="small"
                  v-model="compId"
                  remote
                  :remote-method="getuserCompanyList"
                  filterable
                  clearable
                  placeholder="请至少输入两个字搜索"
                  style="width:100%"
              >
                <el-option
                    v-for="item in userCompanyList"
                    :key="item.compId"
                    :label="item.compName"
                    :value="item.compId"
                ></el-option>
              </el-select>
            </div>
            <div class="df">
              <el-button type="primary" class="bgc-bv" round @click="getData()">查询</el-button>
              <el-button type="primary" class="bgc-bv" round @click="handleEdit('add')">新增</el-button>
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
                ref="multipleTable"
                :data="tableData"
                :height="tableHeight"
                size="small"
                tooltip-effect="dark"
                style="width: 100%"
                :header-cell-style="tableHeader"
            >
              <el-table-column
                  label="序号"
                  align="center"
                  type="index"
                  :index="indexMethod"
                  min-width="100"
              />
              <el-table-column
                  label="机构名称"
                  align="center"
                  show-overflow-tooltip
                  prop="compName"
                  min-width="150"
              />
              <el-table-column
                  label="所在区域"
                  align="center"
                  show-overflow-tooltip
                  prop="areaNamePath"
                  min-width="150"
              />
              <el-table-column
                  label="操作人"
                  align="center"
                  show-overflow-tooltip
                  min-width="110"
              >
                <template slot-scope="scope">{{scope.row.updateUser?scope.row.updateUser:scope.row.createUser?scope.row.createUser:'--' }}</template>
              </el-table-column>
              <el-table-column
                  label="操作日期"
                  align="center"
                  show-overflow-tooltip
                  min-width="200"
              >
                <template slot-scope="scope">{{scope.row.updateTime?scope.row.updateTime:scope.row.createTime?scope.row.createTime:'--' }}</template>
              </el-table-column>
              <el-table-column
                  label="状态"
                  align="center"
                  show-overflow-tooltip
                  prop="enabled"
                  min-width="80"
              >
                <template slot-scope="scope">{{scope.row.enabled == '1'?'已启用' : scope.row.enabled == '0'?'已停用' : '--'}}</template>
              </el-table-column>
              <el-table-column
                  label="店铺有效期止"
                  align="center"
                  show-overflow-tooltip
                  prop="shopExpiryDate"
                  min-width="150"
              />
              <el-table-column label="操作" align="center" show-overflow-tooltip min-width="100" fixed="right">
                <div slot-scope="scope" class="flexcc">
                  <el-button
                      type="text"
                      style="padding:0px 5px"
                      size="mini"
                      @click="handleEdit('edit',scope.row.configId,scope.row.enabled)"
                  >编辑</el-button>
                  <el-button
                      type="text"
                      style="padding:0px 5px"
                      size="mini"
                      v-if="scope.row.enabled == '0'"
                      @click="handleEnabled(scope.row.configId,scope.row.enabled)"
                  >启用</el-button>
                  <el-button
                      type="text"
                      style="padding:0px 5px"
                      size="mini"
                      v-if="scope.row.enabled == '1'"
                      @click="handleEnabled(scope.row.configId,scope.row.enabled)"
                  >停用</el-button>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" />
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
// import tree from "@/components/treePopup";
import moment from "moment";
import { mapGetters } from "vuex";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "operate/appraisalOrganSetupList",
  components: {
    Empty,
    PageNum,
    // tree
  },
  mixins: [List],
  data() {
    return {

      userCompanyList: [], //机构列表
      compId: "",
      params: {}
    };
  },
  created() {},
  mounted() {
    this.getTableHeight();
    // this.getareatree();
  },
  methods: {
    /* 机构名称 */
    getuserCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
            .then((res) => {
              if (res.status == 0) {
                this.userCompanyList = res.data || [];
              }
            })
            .catch(() => {
              return;
            });
      } else {
        this.userCompanyList = [];
      }
    },
    /* tree */
    // childBack(params) {
    //   this.params = { ...params };
    // },
    // clearParams() {
    //   this.params = {};
    // },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize
        // projectName: this.projectName || "",
      };
      if (this.compId) {
        params.compId = this.compId;
      }

      this.doFetch({
        url: "/biz/ct/company/pageList",
        params,
        pageNum
      });
    },
    // //获取行政区划
    // getareatree() {
    //   this.$post("/sys/area/tree").then(ret => {
    //     this.areatreeList = ret.data;
    //   });
    // },
    // 添加编辑
    handleEdit(stu,configId,enabled) {
      console.log(configId)
      if (stu == 'edit') {
        this.$router.push({
          path: "/web/operate/appraisalOrganSetupUpdate",
          query: {
            stu:stu,
            configId: configId,
            enabled: enabled
          }
        });
      } else {
        this.$router.push({
          path: "/web/operate/appraisalOrganSetupUpdate",
          query: {
            stu:stu,
          }
        });
      }
    },
    // 更改状态
    handleEnabled(configId,enabled){
      let paramsEnabled = false
      let descTxt = ''
      if(enabled == '1'){
        paramsEnabled = false
        descTxt = '禁用'
      }else if(enabled == '0'){
        paramsEnabled = true
        descTxt = '启用'
      }
      let params = {
        configId,
        enabled: paramsEnabled
      }
      this.$post("/biz/ct/company/changeState", params).then(res => {
        if (res.status == "0") {
          this.$message({
            type: "success",
            message: descTxt + '成功'
          });
        }else{
          this.$message({
            type: "error",
            message: descTxt + '失败'
          });
        }
        this.getData(-1);
      })
    }

  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function(val, oldVal) {
        console.log(val.query)
        if (val.query.refresh == 'true') {
          this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true
    }
  }
};
</script>
<style lang="less" scope>
.searchBox {
  padding: 0 !important;
  .el-icon-arrow-down:before {
    content: "\e6df" !important;
  }
}
</style>
